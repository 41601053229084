<template>
    <div class="full-calendar">
        <div class="full-calendar-ele"></div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import moment from "moment-timezone";
import "fullcalendar";
import "fullcalendar/dist/fullcalendar.css";
export default {
    props: {
        events: Array // [{title,url,start,end}]
    },
    computed: {
        ...mapGetters({
            tz: "tz"
        }),
        eventSource() {
            return {
                events: this.events,
                color: "#ebebeb",
                textColor: "#666"
            };
        }
    },
    watch: {
        events: {
            deep: true,
            handler() {
                console.info("event changed", this.eventSource);
                let ele = this.$el.querySelector(".full-calendar-ele");
                $(ele).fullCalendar("removeEventSources");
                $(ele).fullCalendar("addEventSource", this.eventSource);
            }
        }
    },
    methods: {
        init() {
            let ele = this.$el.querySelector(".full-calendar-ele");
            let comp = this;

            $(ele).fullCalendar({
                header: {
                    left: "prev,next today",
                    center: "title",
                    right: "month,listWeek"
                },
                selectable: true,
                timezone: comp.tz,
                eventLimit: 10,
                eventSources: [comp.eventSource],
                height: window.innerHeight - 300,

                windowResize(view) {
                    $(ele).fullCalendar("option", "height", window.innerHeight - 300);
                },
                eventRender(event, element, view) {
                    console.info("event", event);
                    $(".fc-list-item-title a", element).text(event.listTitle + moment(event.start).format("hh:mm:ss A"));
                    if (event.url) {
                        $(element).on("click", function(e) {
                            e.preventDefault();
                            e.stopPropagation();
                            // console.info(event.url);
                            comp.$router.push(event.url);
                        });
                    }

                    $(element).text(event.title);

                    // element.innerText = title;
                },
                // dayRender({ date, el, view }) {
                //     console.info(date);
                //     let block = comp.blocks.find(b => {
                //         if (moment(date).diff(moment(b.begin).startOf("day")) > 0 && moment(date).diff(moment(b.end).endOf("day")) < 0) {
                //             console.info("block ", date);
                //             return true;
                //         }
                //         return false;
                //     });
                //     console.info(block);
                // },
                dayClick(date, jsEvent, view) {
                    console.info(date, jsEvent, view);
                    comp.$emit("dayClick", date, jsEvent, view);
                }
            });
        }
    },
    mounted() {
        setTimeout(() => {
            this.init();
        }, 0);
    }
};
</script>
<style lang="less">
.full-calendar {
    .full-calendar-ele {
        // min-height: 500px;
    }
    .fc-more-popover {
        .fc-body {
            max-height: 40vh;
            overflow: auto;
        }
    }
    .fc-month-view {
        .fc-day {
            cursor: pointer;
        }
    }
}
</style>
