<template>
    <div class="order-list">
        <div class="md-layout" style="padding: 2px 10px; box-sizing:border-box">
            <order-content :isManage="true"></order-content>
        </div>
    </div>
</template>

<script>
import OrderContent from "@/components/Order/OrderContent";
import { orderStatus } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            orders: [],
            page: {}
        };
    },
    components: {
        OrderContent
    },
    computed: {
        ...mapGetters({ user: "user/user" })
    },
    methods: {
        // async loadOrders() {
        //     let result = await this.$store.dispatch("crud/get", {
        //         api: "orders",
        //         params: {}
        //     });
        //     this.orders = result.data;
        //     this.page = result.page;
        // }
    },
    mounted() {
        // this.loadOrders();
    }
};
</script>
<style lang="less">
.order-list {
    .md-tabs .md-tabs-navigation {
        background: transparent;
    }

    .md-tabs .md-tab-header {
        color: #666 !important;
    }

    .md-tabs .md-tab-header.md-active {
        color: #2196f3 !important;
    }

    .md-tabs .md-tabs-navigation .md-tab-indicator {
        background: #2196f3;
    }
}
</style>