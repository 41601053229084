<template>
    <div class="order-calendar">
        <md-card>
            <md-card-header>
                <md-speed-dial md-direction="right" md-open="click" style="justify-content:left;margin-bottom:2px">
                    <md-button class="md-icon-button md-raised" md-fab-trigger>
                        <md-icon md-icon-morph>close</md-icon>
                        <md-icon>message</md-icon>
                    </md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#E97F0F">Pending</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#27BAA6">Booked</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#FF0002">Awaiting Payment</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#000000">Rescheduled</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#2089C9">Delivered</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#C433FF">Returned</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#76BA27">Completed</md-button>
                    <md-button class="md-primary md-mini md-raised" style="background:#989898">Cancelled</md-button>
                </md-speed-dial>
                <p>timezone - {{ tz }}</p>
            </md-card-header>
            <md-card-content>
                <full-calendar :events="events" @dayClick="dayClick"></full-calendar>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import FullCalendar from "@/components/FullCalendar";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
    components: {
        FullCalendar
    },
    data() {
        return {
            orders: [],
            vacations: []
        };
    },
    computed: {
        ...mapGetters({
            tz: "tz",
            user: "user/user",
            hasRole: "user/hasRole"
        }),

        events() {
            const items = this.orders
                .filter(order => order.items && order.items.length)
                .reduce((acc, order) => {
                    let groupItems = {};

                    order.items.map(item => {
                        item.order = Object.assign({}, order);
                        item.order.items = undefined;
                        item.start = moment(item.rentalStartDate).toDate();
                        item.end = item.product.rental ? moment(item.rentalEndDate).toDate() : moment(item.rentalStartDate).toDate();
                        let key = `${moment(item.start).format("YYYY-MM-DD HH:mm:ss")}-${moment(item.end).format("YYYY-MM-DD HH:mm:ss")}`;
                        groupItems[key] = item;
                        return item;
                    });

                    return acc.concat(Object.values(groupItems));
                }, []);

            const orderEvents = items.map(i => ({
                title: `${i.order.user_name} - ${i.order.orderNo}`,
                // listTitle: `${i.order.user_name} / ${i.order.orderNo} / ${i.product.name} ${i.variant.name || ""}`,
                url: `/admin/orders/editDetail/${i.order._id}`,
                start: moment(i.rentalStartDate),
                end: i.product.rental ? moment(i.rentalEndDate) : moment(i.rentalStartDate),
                color:
                    {
                        Pending: "#E97F0F ",
                        Booked: "#27BAA6",
                        "Awaiting Payment": "#FF0002 ",
                        Rescheduled: "#000000",
                        Delivered: "#2089C9",
                        Returned: "#C433FF",
                        Completed: "#76BA27",
                        Cancelled: "#989898"
                    }[i.order.status] || "#ebebeb",
                textColor: "#fff"
            }));

            const blockEvents = this.vacations.map(v => ({
                rendering: "background",
                title: "Blocked Out",
                // title: `${i.order.user_name}`,
                // listTitle: `${i.order.user_name} / ${i.order.orderNo} / ${i.product.name} ${i.variant.name || ""}`,
                start: moment(v.begin).startOf("d"),
                end: moment(v.end)
                    .add(1, "d")
                    .endOf("d"),
                allDay: true,
                block: true
                // backgroundColor: "yellow",
                // color: "black",
                // textColor: "#fff"
            }));

            return [].concat(blockEvents, orderEvents);
            // return [].concat(blockEvents);
        }
    },
    methods: {
        async loadOrders() {
            let result = await this.$store.dispatch("crud/get", {
                api: "orders",
                params: {
                    pageSize: 0
                }
            });
            this.orders = result.data;
        },
        async loadVacations() {
            console.info(1232132131);
            if (!this.hasRole("manager")) {
                let result = await this.$store.dispatch("crud/get", {
                    api: "vacations",
                    params: {
                        pageSize: 0,
                        active: true,
                        uid: this.hasRole("manager") ? undefined : this.asRole("staff") ? this.user.staff_vendor_id : this.user._id
                    }
                });
                this.vacations = result.data;
                console.info("vacation", this.vacations);
            }
        },
        dayClick(date, jsEvent, view) {
            this.$router.push(`/admin/orders/manage?defaultstartdate=${moment(date).format("YYYY-MM-DD")}`);
        }
    },
    async mounted() {
        await this.loadOrders();
        await this.loadVacations();
    }
};
</script>
<style lang="less">
.order-calendar {
    .md-card {
        max-width: 1600px;
        margin: auto;
        .md-card-header {
            p {
                margin: 0;
            }
        }
    }
    .color-block {
        display: inline-block;
        width: 30px;
        height: 17px;
        border-radius: 2px;
        vertical-align: middle;
    }
}
</style>
