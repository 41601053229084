<template>
    <div class="vacation-index">
        <md-tabs v-if="user" ref="tabs" @change="tabChange">
            <md-tab md-label="Manage Orders" :md-active="tabIndex == 0">
                <order-list v-if="tabIndex == 0"></order-list>
            </md-tab>
            <md-tab md-label="Calendar View" :md-active="tabIndex == 1">
                <order-calendar v-if="tabIndex == 1"></order-calendar>
            </md-tab>
            <md-tab md-label="Add Order" :md-disabled="user.role == 'staff' && !hasPermission('edit_order')" :md-active="tabIndex == 2">
                <order-add v-if="tabIndex == 2"></order-add>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderMy from "@/components/Order/OrderMy";
import OrderList from "@/components/Order/OrderList";
import OrderCalendar from "@/components/Order/OrderCalendar";
import OrderAdd from "@/components/Order/OrderAdd";
export default {
    props: ["id"],
    components: {
        OrderMy,
        OrderList,
        OrderCalendar,
        OrderAdd
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasPermission: "user/hasPermission"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                let path = this.$route.path;
                console.info(path);
                if (!this.id) {
                    this.$router.replace("/admin/orders/manage");
                } else if (this.id == "manage") {
                    this.tabIndex = 0;
                } else if (this.id == "calendar") {
                    this.tabIndex = 1;
                } else if (this.id == "new") {
                    this.tabIndex = 2;
                }
                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }
            if (index == 0) {
                this.$router.push({
                    path: "/admin/orders/manage",
                    query: this.$route.query
                });
            }
            if (index == 1) {
                this.$router.push(`/admin/orders/calendar`);
            }
            if (index == 2) {
                this.$router.push({
                    path: `/admin/orders/new`
                    // query: this.$route.query
                });
            }
        }
    }
};
</script>
