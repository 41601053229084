<template>
    <div class="order-add">
        <order-edit></order-edit>
    </div>
</template>
<script>
const OrderEdit = () => import("@/components/Order/OrderEdit");

export default {
    components: {
        OrderEdit
    }
};
</script>